import React from "react"
import * as style from "./style.module.scss"

const Page404 = () => {
  return (
    <div className="tada__block">
      <div className={style.error}>
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className={style.description}>
              <h1 className={style.title}>Page 404</h1>
              <p className="mb-4">Coming soon</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className={style.pig}>
              <img src="/resources/images/pig.svg" title="404" alt="404" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page404
